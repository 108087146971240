// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-catalogue-js": () => import("./../../../src/pages/catalogue.js" /* webpackChunkName: "component---src-pages-catalogue-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-catalogue-js": () => import("./../../../src/pages/en/catalogue.js" /* webpackChunkName: "component---src-pages-en-catalogue-js" */),
  "component---src-pages-en-game-index-js": () => import("./../../../src/pages/en/game/index.js" /* webpackChunkName: "component---src-pages-en-game-index-js" */),
  "component---src-pages-en-game-play-js": () => import("./../../../src/pages/en/game/play.js" /* webpackChunkName: "component---src-pages-en-game-play-js" */),
  "component---src-pages-en-how-js": () => import("./../../../src/pages/en/how.js" /* webpackChunkName: "component---src-pages-en-how-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-game-index-js": () => import("./../../../src/pages/game/index.js" /* webpackChunkName: "component---src-pages-game-index-js" */),
  "component---src-pages-game-play-js": () => import("./../../../src/pages/game/play.js" /* webpackChunkName: "component---src-pages-game-play-js" */),
  "component---src-pages-game-share-1-js": () => import("./../../../src/pages/game/share/1.js" /* webpackChunkName: "component---src-pages-game-share-1-js" */),
  "component---src-pages-game-share-2-js": () => import("./../../../src/pages/game/share/2.js" /* webpackChunkName: "component---src-pages-game-share-2-js" */),
  "component---src-pages-game-share-3-js": () => import("./../../../src/pages/game/share/3.js" /* webpackChunkName: "component---src-pages-game-share-3-js" */),
  "component---src-pages-how-js": () => import("./../../../src/pages/how.js" /* webpackChunkName: "component---src-pages-how-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-trash-js": () => import("./../../../src/templates/trash.js" /* webpackChunkName: "component---src-templates-trash-js" */)
}

